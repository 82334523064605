import React, { useState, useEffect, useRef } from 'react';
import { Calendar, Camera, Users, FileText, ChevronDown, X, Mail, Send ,calendar, QrCodeIcon, Bell, UserCog ,  LogIn,  Layout, Sparkles,Home, ScanLine, ExternalLink,Play  } from 'lucide-react';
import EdgeCompatibleVideoPlayer from './EdgeCompatibleVideoPlayer';

import videophone1 from "./assets/img/video1.mp4" ;
// import feturevideo1 from './assets/img/Medien1.MP4';
const LandingPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [activeFeature, setActiveFeature] = useState(null);
  const [isPhoneMinimized, setIsPhoneMinimized] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showPhone, setShowPhone] = useState(false);
  const [showModalAnimation, setShowModalAnimation] = useState(false);
  const [hoveredVideo, setHoveredVideo] = useState(null);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [scrollIndicatorVisible, setScrollIndicatorVisible] = useState(true);
  const [lastScrollProgress, setLastScrollProgress] = useState(0);
  // Bestehender Code bleibt gleich bis zum expandedFeature...
 
  // Wenn ein Feature geöffnet wird
 
    const [copied, setCopied] = useState(false);
 
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset "Copied!" message after 2 seconds
        })
        .catch((err) => console.error('Error copying to clipboard:', err));
    };
 
  // Wenn das Modal geschlossen wird
  const closeFeature = () => {
    setShowModalAnimation(false);
    setTimeout(() => setActiveFeature(null), 500); // Warte auf Animation
  };
  const phoneRef = useRef(null);
  const explosionRef = useRef(null);
  const titleRef = useRef(null);
  const featuresRef = useRef(null);
 
  const CustomVideoPlayer = ({ videoSrc }) => {
    const videoRef = useRef(null);
    const timeoutRef = useRef(null);
  
    useEffect(() => {
      const video = videoRef.current;
      
      const handleTimeUpdate = () => {
        if (video.duration - video.currentTime < 0.1) {
          video.pause();
          
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          
          timeoutRef.current = setTimeout(() => {
            video.currentTime = 0;
            video.play().catch(err => console.log('Playback failed:', err));
          }, 1000);
        }
      };
  
      video.addEventListener('timeupdate', handleTimeUpdate);
      video.play().catch(err => console.log('Initial playback failed:', err));
  
      return () => {
        video.removeEventListener('timeupdate', handleTimeUpdate);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);
  
    return (
      <video
        ref={videoRef}
        src={videoSrc}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
        muted
        playsInline
        loop
      />
    );
  };
 
  const features = [
    {
      title: "Welcome & Login",
      description: "Your personalized access to the camp experience - simple and secure",
      icon: <LogIn size={28} />,
      video: "/assets/img/EEZM4765.webm",
      color: "#FF3B30",
      details: [
        "Quick login with existing account",
        "Easy registration for new participants",
        "Personal profile customization"
      ]
    },
    {
      title: "Home Screen",
      description: "Your  dashboard with all essential information at a glance",
      icon: <Home size={28} />,
      video: "/assets/img/Screen_Recording_20241204_092826_Expo Go (2).webm",
      color: "#FF3B30",
      details: [
        "Events and schedules",
        "Quick access to daily plan",
        "Important announcements overview"
      ]
    },
    {
      title: "Profile Management",
      description: "Manage your personal data independently and hassle-free",
      icon: <UserCog size={28} />,
      video: "/assets/img/HAAF2849.webm",
      color: "#FF2D55",
      details: [
        "Flexible data updates",
        "Profile picture and account settings",
        "Clear data management"
      ]
    },
    {
      title: "Quick Check-Out",
      description: "Saying goodnight has never been easier - with your personal QR code",
      icon: <QrCodeIcon size={28} />,
      video: "/assets/img/HGOGE4180.webm",
      color: "#FF375F",
      details: [
        "Fast check-out via QR code",
        "Direct confirmation by supervisors",
        "No more paper lists needed"
      ]
    },
    {
      title: "Personal QR Profile",
      description: "Your digital identity - scan and share your profile instantly",
      icon: <QrCodeIcon size={28} />,
      video: "/assets/img/OYTA6190.webm",
      color: "#FF2D55",
      details: [
        "Unique personal QR code",
        "Quick profile sharing",
        "Instant profile viewing"
      ]
    },
    {
      title: "Persons In Charge Screen",
      description: "All contacts and supervisors at a glance",
      icon: <Users size={28} />,
      video: "/assets/img/REZF5593.webm",
      color: "#FF4F55",
      details: [
        "Complete overview of all vocational trainers",
        "Videos on every vocational trainer",
        "Clear responsibilities overview"
      ]
    },
    {
      title: "Schedule & Events",
      description: "Your digital camp planner with all important dates and events",
      icon: <Calendar size={28} />,
      video: "/assets/img/VHYG4124.webm",
      color: "#FF5B65",
      details: [
        "Daily overview of all activities",
        "Detailed timetables",
        "Easy schedule navigation"
      ]
    },
    {
      title: "Smart Notch",
      description: "Intelligent information display for quick overview",
      icon: <Layout size={28} />,
      video: "/assets/img/WBDR4709.webm",
      color: "#FF6575",
      details: [
        "Next events at a glance",
        "Expandable schedule overview",
        "Important alerts in focus"
      ]
    },
    {
      title: "Coming Soon",
      description: "Stay tuned for exciting new features!",
      icon: <Sparkles size={28} />,
      video: "/assets/img/soon.webm",
      color: "#FF7585",
      details: [
        "More features in development",
        "Enhanced camp experience",
        "Continuous improvements"
      ]
    }
];
 
useEffect(() => {
  // Initial setup timeouts
  setTimeout(() => setIsLoaded(true), 100);
  setTimeout(() => setShowPhone(true), 1000);

  // Combined scroll handler
  const handleScroll = () => {
    const scrolled = window.scrollY;
    const winHeight = window.innerHeight ;
    const currentProgress = (scrolled / winHeight) * 100;
    setScrollProgress(currentProgress);
    
    // Handle scroll indicator visibility
    if (currentProgress >= 100 && lastScrollProgress < 100) {
      setScrollIndicatorVisible(false);
      setTimeout(() => {
        setScrollIndicatorVisible(true);
        setTimeout(() => {
          setScrollIndicatorVisible(false);
        }, 2000);
      }, 500);
    } else if (currentProgress < 100) {
      setScrollIndicatorVisible(true);
    }

    setLastScrollProgress(currentProgress);
    // Phone minimization
    setIsPhoneMinimized(scrolled > winHeight * 0.6);
  };

  // Mouse movement handler
  const handleMouseMove = (e) => {
    if (!isPhoneMinimized) {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      });

      if (explosionRef.current) {
        const xOffset = (e.clientX / window.innerWidth - 0.5) * 35;
        const yOffset = (e.clientY / window.innerHeight - 0.5) * 35;
        explosionRef.current.style.transform = `translate(${xOffset}px, ${yOffset}px)`;
      }
    }
  };

  // Add event listeners
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('mousemove', handleMouseMove);

  // Cleanup
  return () => {
    window.removeEventListener('scroll', handleScroll);
    window.removeEventListener('mousemove', handleMouseMove);
  };
}, [isPhoneMinimized, lastScrollProgress]); // Added lastScrollProgress to dependencies

  const buttonBaseStyle = {
    padding: '8px',
    background: 'transparent',
    border: '1px solid rgba(255,255,255,0.2)',
    borderRadius: '8px',
    color: '#FF3B30',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    width: '48%',
    fontSize: '14px',
    zIndex: 10,
    position: 'relative',
    userSelect: 'none'
  };
 
    const openOutlookMail = () => {
      window.location.href = 'mailto:neo.stutz@sunrise.net';
    };
    const mailtoLink = {
      ...buttonBaseStyle,
      textDecoration: 'none',
      background: 'transparent',
    };
   
const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0,0,0,0.95)',
    backdropFilter: 'blur(20px)',
    zIndex: 1000,
    display: showModalAnimation ? 'flex' : 'none',
    flexDirection: 'column',
    padding: '20px',
    transform: showModalAnimation ? 'translateY(0)' : 'translateY(100%)',
    opacity: showModalAnimation ? 1 : 0,
    transition: 'all 0.5s cubic-bezier(0.16, 1, 0.3, 1)',
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: '40vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    overflow: 'hidden',
    transform: showModalAnimation ? 'scale(1)' : 'scale(0.9)',
    opacity: showModalAnimation ? 1 : 0,
    transition: 'all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1) 0.2s',
    // Neuer Media Query für Desktop
    '@media (min-width: 1024px)': {
      height: '70vh',
    },
  },
  videoWrapper: {
    position: 'relative',
    height: '100%',
    // Auf Desktop maximal Handybreite
    maxWidth: '375px',
    aspectRatio: '9/16',
    margin: '0 auto',
    background: '#000',
    borderRadius: '20px',
    overflow: 'hidden',
    boxShadow: '0 20px 40px rgba(0,0,0,0.3)',
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },};
  const styles = {
    container: {
      background: '#000',
      color: '#fff',
      minHeight: '400vh',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
     
      overflow: 'hidden',
     
      '@media (min-width: 1024px)': {
        minWidth: '100vw', // Wichtig: Verwendet viewport width
        maxWidth: '100vw', // Begrenzt auf viewport width
      }
    },
    comingSoon: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#000',
      zIndex: 1000,
      opacity: Math.max(0, 1 - scrollProgress / 10),
      transform: `scale(${Math.max(0.8, 1 - scrollProgress / 40)})`,
      pointerEvents: scrollProgress > 20 ? 'none' : 'auto',
      transition: 'all 0.8s cubic-bezier(0.16, 1, 0.3, 1)',
    },
  comingSoonText: {
    fontSize: 'clamp(24px, 10vw, 120px)', // Adjust for responsiveness
    fontWeight: '800',
    background: 'linear-gradient(45deg, #fff, #666)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    marginBottom: '20px',
    transform: `translateY(${scrollProgress * -1.5}px)`,
  },
    explosionEffect: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: '100vh',
      background: `
        radial-gradient(
          circle at 50% ${50 + (scrollProgress * 0.6)}%,
          rgba(255,59,48,0.2) 0%,
          rgba(255,45,85,0.15) 30%,
          rgba(0,0,0,0) 70%
        )
      `,
      transform: `scale(${1 + scrollProgress * 0.01})`,
      transformOrigin: 'center bottom',
      transition: 'all 0.5s cubic-bezier(0.16, 1, 0.3, 1)',
      opacity: Math.max(1 - scrollProgress * 0.01, 0.2),
      zIndex: 1,
    },
    nav: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      padding: '20px 40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: 'rgba(0,0,0,0.8)',
      backdropFilter: 'blur(20px)',
      borderBottom: '1px solid rgba(255,59,48,0.1)',
      transform: `translateY(${scrollProgress > 70 ? 0 : -100}px)`,
      transition: 'all 0.6s cubic-bezier(0.16, 1, 0.3, 1)',
      zIndex: 100,
    },
    logo: {
      fontSize: '24px',
      fontWeight: 'bold',
      background: 'linear-gradient(to right, #FF3B30, #FF2D55)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    scrollProgress: {
      position: 'fixed',
      top: '80px',
      left: '50%',
      transform: 'translateX(-50%)',
      background: 'rgba(0,0,0,0.8)',
      padding: '20px 26px',
      borderRadius: '20px',
      fontSize: '14px',
      color: '#fff',
      zIndex: 1000,
      alignItems:"center",
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255,255,255,0.1)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255,255,255,0.1)',
      opacity: scrollIndicatorVisible ? 1 : 0,
      visibility: scrollIndicatorVisible ? 'visible' : 'hidden',
      transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
      transform: scrollIndicatorVisible 
        ? 'translate(-50%, 0) scale(1)' 
        : 'translate(-50%, -20px) scale(0.8)',
    },

    progressText: {
      display: 'flex',
      alignItems: 'center',
      gap: '0px',
    },

    progressNumber: {
      fontWeight: '600',
      color: scrollProgress >= 100 ? '#FF3B30' : '#fff',
      transition: 'color 0.9s ease',
    },

    titleContainer: {
      position: 'fixed',
      top: '0%',
      left: '50%',
      transform: isPhoneMinimized ?
        'translate(-50%, -20%) scale(0.8)' :
        'translate(-50%, 0) scale(1)',
      zIndex: 2,
      textAlign: 'center',
      opacity: scrollProgress > 85 ? 0 : 1,
      transition: 'all 1s cubic-bezier(0.16, 1, 0.3, 1)',
    },
    title: {
      fontSize: '72px',
      fontWeight: '800',
      marginBottom: '10px',
      background: 'linear-gradient(to right, #FF3B30, #FF2D55)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      transform: `translateY(${scrollProgress * -0.5}px)`,
      opacity: Math.max(1 - scrollProgress * 0.005, 0),
    },
    subtitle: {
      fontSize: '34px',
      color: 'rgba(255,255,255,0.8)',
      maxWidth: '600px',
      margin: '0 auto',
      transform: `translateY(${scrollProgress * -0.3}px)`,
      opacity: Math.max(1 - scrollProgress * 0.005, 0),
    },
    phoneContainer: {
      position: 'fixed',
      top: '60%',
      left: '50%',
      transform: isPhoneMinimized
        ? 'translate(-50%, -90%) scale(0.5)'
        : showPhone
          ? 'translate(-50%, -50%) scale(1)'
          : 'translate(-50%, 100vh) scale(1)',
      perspective: '2000px',
      zIndex: 2,
      transition: 'all 1.5s cubic-bezier(0.34, 1.56, 0.64, 1)',
      opacity: scrollProgress > 80 ? 0 : 1,
    },
    phone: {
      position: 'relative',
      height: '600px',
      width: 'auto',
      aspectRatio: '9/19',
      maxHeight: '85vh',
      transformStyle: 'preserve-3d',
      transform: isPhoneMinimized ?
        'rotateX(10deg) rotateY(0deg)' :
        `rotateX(${Math.min(scrollProgress * 0.1, 10)}deg) rotateY(${(mousePosition.x - window.innerWidth / 2) * 0.02}deg)`,
      transition: 'transform 0.8s cubic-bezier(0.16, 1, 0.3, 1)',
    },
    phoneFrame: {
      position: 'relative',
      height: '100%',
      width: '100%',
      padding: '10px',
      background: 'linear-gradient(145deg, rgba(255,255,255,0.1), rgba(255,255,255,0.05))',
      borderRadius: '50px',
      boxShadow: `
        0 20px 40px rgba(0,0,0,0.3),
        0 0 0 1px rgba(255,255,255,0.1),
        inset 0 0 0 1px rgba(255,255,255,0.05)
      `,
      opacity: isLoaded ? 1 : 0,
      transform: isLoaded ? 'translateY(0)' : 'translateY(100vh)',
      transition: 'all 1.5s cubic-bezier(0.16, 1, 0.3, 1)',
    },  featureCard: {
      position: 'relative',
      background: 'rgba(255,59,48,0.05)',
      borderRadius: '24px',
      padding: '30px',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255,59,48,0.2)',
      cursor: 'pointer',
      transition: 'all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: '400px',
      margin: '0 auto',
      overflow: 'hidden',
      transform: 'translateY(0px)',
    },

    featureCardActive: {
      background: 'rgba(255,59,48,0.1)',
      transform: 'translateY(-10px) scale(1.03)',
      boxShadow: `
        0 20px 40px rgba(255,59,48,0.2),
        0 0 0 1px rgba(255,59,48,0.3),
        0 0 40px rgba(255,59,48,0.1)
      `,
      border: '1px solid rgba(255,59,48,0.4)',
    },

    featureVideo: {
      width: '100%',
      aspectRatio: '8.2/16',
      objectFit: 'cover',
      borderRadius: '16px',
      marginBottom: '20px',
      transition: 'all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1)',
      transform: 'scale(1)',
      border: '1px solid rgba(255,59,48,0.2)',
      boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
    },

    featureVideoActive: {
      transform: 'scale(1.07)',
      borderRadius: '20px',
      border: '1px solid rgba(255,59,48,0.4)',
      boxShadow: '0 15px 30px rgba(255,59,48,0.2)',
      marginTop:"20px",
    },

    featureContent: {
      width: '100%',
      textAlign: 'center',
      transition: 'all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1)',
      transform: 'translateY(0)',
    },

    featureIcon: {
      width: '64px',
      height: '64px',
      borderRadius: '20px',
      background: 'linear-gradient(135deg, rgba(255,59,48,0.8), rgba(255,59,48,0.4))',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '20px',
      transition: 'all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1)',
      transform: 'scale(1)',
    },

    featureIconActive: {
      transform: 'scale(1.1)',
      // background: 'linear-gradient(135deg, rgba(255,59,48,1), rgba(255,59,48,0.8))',
      // boxShadow: '0 10px 20px rgba(255,59,48,0.3)',
      marginTop: '35px',
marginLeft:"40%"  
  },

    featureTitle: {
      fontSize: '24px',
      fontWeight: '600',
      marginBottom: '12px',
      color: '#fff',
      // transition: 'all 0.3s ease',
    },

    featureDescription: {
      fontSize: '16px',
      lineHeight: 1.6,
      color: 'rgba(255,255,255,0.7)',
      // transition: 'all 0.3s ease',
    },

    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      // background: 'linear-gradient(180deg, rgba(255,59,48,0.1) 0%, rgba(255,59,48,0.05) 100%)',
      opacity: 0,
      transition: 'opacity 0.5s ease',
      borderRadius: '24px',
      pointerEvents: 'none',
    },

    overlayActive: {
      opacity: 1,
    },
  
    phoneScreen: {
      width: '100%',
      height: '100%',
      borderRadius: '42px',
      overflow: 'hidden',
      position: 'relative',
      boxShadow: 'inset 0 0 0 1px rgba(255,255,255,0.1)',
    },
    screenContent: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transform: isLoaded ? 'scale(1)' : 'scale(1.1)',
      opacity: isLoaded ? 1 : 0,
      transition: 'all 1s cubic-bezier(0.34, 1.56, 0.64, 1)',
    },
    screenOverlay: {
      position: 'absolute',
      inset: 0,
      background: 'linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,0.2) 100%)',
      zIndex: 2,
    },
    features: {
      position: 'relative',
      padding: '100vh 0 100px', // Entfernt horizontales Padding
      width: '100vw', // Verwendet viewport width
      minWidth: '100vw',
      maxWidth: '100vw',
      margin: 0,
      zIndex: 3,
      opacity: scrollProgress > 90 ? 1 : 0,
      transform: `translateY(${Math.max(0, 50 - scrollProgress)}px)`,
      transition: 'all 1s cubic-bezier(0.16, 1, 0.3, 1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      // left:"50px"
    },
   
    featureCard: {
      position: 'relative',
      background: 'rgba(255,59,48,0.09)',
      borderRadius: '16px',
      padding: '20px',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255,59,48,0.3)',
      cursor: 'pointer',
      transition: 'all 0.5s cubic-bezier(0.16, 1, 0.3, 1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',  // Changed from fixed width to be responsive
      maxWidth: '400px',  // Added max-width instead of fixed width
      margin: '0 auto', // Center the card
      '@media (min-width: 768px)': {
        padding: '25px',
        borderRadius: '20px',
        background: 'rgba(255,59,48,1)',
 
      },
      '@media (min-width: 1024px)': {
        padding: '30px',
        borderRadius: '24px',
        background: 'rgba(255,59,48,0.09)',
 
      },
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 20px 40px rgba(255,59,48,0.2)',
      },
    
    },
   
    featureVideo: {
      width: '100%',
      aspectRatio: '8.2/16', // Added to maintain mobile video aspect ratio
      objectFit: 'cover',
      borderRadius: '12px',
      marginBottom: '15px',
      '@media (min-width: 768px)': {
        marginBottom: '30px',
      }
    },
   
    // Update the feature grid to better handle the video layout
    featureGrid: {
      display: 'grid',
      gap: '30px',
      width: '100%',
      padding: '20px',
      maxWidth: '1920px',
      opacity: scrollProgress > 30 ? 1 : 0,
      transform: `translateY(${Math.max(50 - scrollProgress/2, 0)}px)`,
      transition: 'all 1s cubic-bezier(0.16, 1, 0.3, 1)',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', // Increased minimum width
      justifyItems: 'center', // Center items in grid
    },
    
    contactSection: {
      padding: '100px 40px',
      maxWidth: '800px',
      margin: '0 auto',
      textAlign: 'center',
    },
    contactForm: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      marginTop: '40px',
    },
    input: {
      background: 'rgba(255,255,255,0.05)',
      border: '1px solid rgba(255,255,255,0.1)',
      borderRadius: '12px',
      padding: '16px',
      color: '#fff',
      fontSize: '16px',
      width: '100%',
    },
    submitButton: {
      background: 'linear-gradient(to right, #FF3B30, #FF2D55)',
      border: 'none',
      borderRadius: '12px',
      padding: '16px 32px',
      color: '#fff',
      fontSize: '16px',
      fontWeight: '600',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
    },
    progressBar: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      height: '4px',
      background: 'rgba(255,255,255,0.1)',
      zIndex: 1000,
    },
    progressFill: {
      height: '100%',
      width: '60%',
      background: 'linear-gradient(to right, #FF3B30, #FF2D55)',
      position: 'relative',
    },
    progressText: {
      position: 'absolute',
      right: '10px',
      bottom: '10px',
      fontSize: '14px',
      color: 'rgba(255,255,255,0.8)',
    },
    expandedFeature: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: activeFeature ?
        'translate(-50%, -50%) scale(1)' :
        'translate(-50%, -50%) scale(0.9)',
      width: '90vw',
      maxWidth: '1200px',
      height: '80vh',
      background: 'rgba(20,20,20,0.98)',
      backdropFilter: 'blur(30px)',
      borderRadius: '30px',
      padding: '40px',
      zIndex: 1000,
      opacity: activeFeature ? 1 : 0,
      visibility: activeFeature ? 'visible' : 'hidden',
      transition: 'all 0.6s cubic-bezier(0.16, 1, 0.3, 1)',
      boxShadow: '0 50px 100px rgba(0,0,0,0.5)',
    },
  };
 
const handleSubmit = (e) => {
  e.preventDefault();
  // Handle form submission logic here
  console.log('Submitted:', { email, message });
  setEmail('');
  setMessage('');
};
 
const openFeature = (feature) => {
  setActiveFeature(feature);
  setIsPhoneMinimized(true);
  // Verzögerte Animation starten
  setTimeout(() => setShowModalAnimation(true), 100);
};
 
return (
  <div style={styles.container}>
    <div style={styles.comingSoon}>
      <h1 style={styles.comingSoonText}>COMING SOON</h1>
      <ChevronDown size={48} color="#fff" style={{ animation: 'bounce 2s infinite' }} />
    </div>
 
    <nav style={styles.nav}>
      <div style={styles.logo}>Sunrise Lager app</div>
    </nav>
 
    <div style={styles.scrollProgress}>
        <div style={styles.progressText}>
          <span style={styles.progressNumber}>
            {Math.min(Math.round(scrollProgress), 100)}%
          </span>
         
        </div>
      </div>
 
    <div ref={explosionRef} style={styles.explosionEffect} />
 
    <div style={styles.titleContainer} ref={titleRef}>
      <h1 style={styles.title}>Sunrise Lager App</h1>
      <p style={styles.subtitle}>
      "It has never been so easy"
      </p>
    </div>
 
    <div style={styles.phoneContainer}>
      <div style={styles.phone} ref={phoneRef}>
        <div style={styles.phoneFrame}>
        <div style={styles.phoneScreen}>
  <EdgeCompatibleVideoPlayer
  src={videophone1}
  style={styles.phoneScreen}
  autoPlay
  loop
  muted
  playsInline
/>
  <div style={styles.screenOverlay} />
</div>
        </div>
      </div>
    </div>
 
    <section style={styles.features} ref={featuresRef}>
    <h2 style={{
    fontSize: 'clamp(32px, 6vw, 48px)',
    fontWeight: '700',
    marginBottom: '20px',
    textAlign: 'center',
    background: 'linear-gradient(to right, #FF3B30, #FF2D55)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  }}>
   Features
  </h2>
  <div style={styles.featureGrid}>
        {features.map((feature, index) => (
          <div
            key={index}
            style={{
              ...styles.featureCard,
              ...(hoveredFeature === index ? styles.featureCardActive : {}),
            }}
            onClick={() => openFeature(feature)}
            onMouseEnter={() => setHoveredFeature(index)}
            onMouseLeave={() => setHoveredFeature(null)}
          >
            <div style={styles.featureContent}>
              <EdgeCompatibleVideoPlayer
                src={feature.video}
                style={{
                  ...styles.featureVideo,
                  ...(hoveredFeature === index ? styles.featureVideoActive : {}),
                }}
                muted
                loop
                playsInline
                autoPlay={hoveredFeature === index}
              />
              
              <div style={{
                ...styles.featureIcon,
                ...(hoveredFeature === index ? styles.featureIconActive : {}),
              }}>
                {feature.icon}
              </div>

              <h3 style={{
                ...styles.featureTitle,
                color: hoveredFeature === index ? '#fff' : 'rgba(255,255,255,0.9)',
              }}>
                {feature.title}
              </h3>

              <p style={{
                ...styles.featureDescription,
                color: hoveredFeature === index ? 'rgba(255,255,255,0.9)' : 'rgba(255,255,255,0.7)',
              }}>
                {feature.description}
              </p>
            </div>

            <div style={{
              ...styles.overlay,
              ...(hoveredFeature === index ? styles.overlayActive : {}),
            }} />
          </div>
        ))}
      
      </div>
    </section>
    <section style={{
      padding: '20px',
      backgroundColor: '#1e1e1e',
      color: '#fff',
      borderRadius: '16px',
      width: '90%',
      maxWidth: '800px',
      margin: '20px auto',
      boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
      zIndex: 3,
      position: 'relative',
      bottom:40
    }}>
      <h2 style={{
        fontSize: 'clamp(32px, 6vw, 48px)',
        fontWeight: '700',
        marginBottom: '20px',
        textAlign: 'center',
        background: 'linear-gradient(to right, #FF3B30, #FF2D55)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}>
        Contact & Demo
      </h2>
 
      <div style={{
        background: 'rgba(255,255,255,0.03)',
        borderRadius: '20px',
        padding: '20px',
        width: '100%',
        margin: '0 auto',
        border: '1px solid rgba(255,255,255,0.1)',
      }}>
        <div style={{
          marginBottom: '20px',
          padding: '20px',
          background: 'rgba(255,59,48,0.05)',
          borderRadius: '16px',
          border: '1px solid rgba(255,59,48,0.1)',
        }}>
          <h3 style={{
            fontSize: '20px',
            fontWeight: '600',
            color: '#fff',
            marginBottom: '10px',
            textAlign: 'center'
          }}>
            A Digital Avengers Lab Project
          </h3>
        </div>
 
        <div style={{
          marginBottom: '20px',
          padding: '20px',
          background: 'rgba(255,59,48,0.05)',
          borderRadius: '16px',
          border: '1px solid rgba(255,59,48,0.1)',
        }}>
          <h3 style={{
            fontSize: '20px',
            fontWeight: '600',
            color: '#fff',
            marginBottom: '15px',
            textAlign: 'center',
          }}>
            Developer Contact
          </h3>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}>
            <div style={{
              width: '80px',
              height: '80px',
              borderRadius: '50%',
              background: 'linear-gradient(135deg, #FF3B30, #FF2D55)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '5px',
            }}>
              <Users size={32} color="white" />
            </div>
            <div style={{
              textAlign: 'center',
              color: '#fff',
            }}>
              <h4 style={{
                fontSize: '18px',
                fontWeight: '600',
                marginBottom: '5px',
              }}>
                Neo Stutz
              </h4>
              <p style={{
                color: 'rgba(255,255,255,0.7)',
                marginBottom: '10px',
                fontSize: '14px',
              }}>
                Lead Developer - Digital Avengers Lab
              </p>
            </div>
          </div>
        </div>
 
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          marginBottom: '20px',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '15px',
            background: 'rgba(255,255,255,0.05)',
            borderRadius: '12px',
            border: '1px solid rgba(255,255,255,0.1)',
            userSelect: 'text',
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}>
              <Mail size={20} color="#FF3B30" />
              <div style={{ flex: 1 }}>
                <div style={{ fontWeight: '600', fontSize: '14px' }}>Email</div>
                <div style={{
                  color: 'rgba(255,255,255,0.7)',
                  userSelect: 'text',
                  fontSize: '14px',
                  wordBreak: 'break-all'
                }}>
                  neo.stutz@sunrise.net
                </div>
              </div>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              width: '100%'
            }}>
               <button
          onClick={() => copyToClipboard('neo.stutz@sunrise.net')}
          style={{
            ...buttonBaseStyle,
            background: copied ? 'rgba(255,255,255,0.1)' : 'transparent',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.background = 'rgba(255,255,255,0.1)';
          }}
          onMouseLeave={(e) => {
            if (!copied) {
              e.currentTarget.style.background = 'transparent';
            }
          }}
        >
          {copied ? 'Copied!' : 'Copy Email'}
        </button>
       
        {/* Changed button to anchor tag with mailto: */}
        <a
          href="mailto:neo.stutz@sunrise.net"
          style={mailtoLink}
          onMouseEnter={(e) => {
            e.currentTarget.style.background = 'rgba(255,255,255,0.1)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.background = 'transparent';
          }}
        >
          Open in Mail <ExternalLink size={16} />
        </a>
      </div>
          </div>
        </div>
 
        <div style={{
          padding: '20px',
          background: 'rgba(255,255,255,0.03)',
          borderRadius: '16px',
          border: '1px solid rgba(255,255,255,0.1)',
          textAlign: 'center',
        }}>
          <h3 style={{
            fontSize: '18px',
            fontWeight: '600',
            color: '#fff',
            marginBottom: '10px',
          }}>
            Interested in a Demo?
          </h3>
          <p style={{
            color: 'rgba(255,255,255,0.7)',
            marginBottom: '15px',
            lineHeight: '1.5',
            fontSize: '14px',
          }}>
            Contact me for a personal demonstration of the app and its features.
          </p>
          <div style={{
            marginTop: '20px',
            padding: '15px',
            background: 'rgba(255,59,48,0.05)',
            borderRadius: '12px',
            border: '1px solid rgba(255,59,48,0.1)',
          }}>
            <p style={{
              color: 'rgba(255,255,255,0.6)',
              fontSize: '14px'
            }}>
              Development Progress: 60%
            </p>
            <div style={{
              width: '100%',
              height: '6px',
              background: 'rgba(255,255,255,0.1)',
              borderRadius: '3px',
              marginTop: '10px',
              overflow: 'hidden',
            }}>
              <div style={{
                width: '60%',
                height: '100%',
                background: 'linear-gradient(to right, #FF3B30, #FF2D55)',
                borderRadius: '3px',
                transition: 'width 1s ease-in-out',
              }} />
            </div>
          </div>
        </div>
      </div>
    </section>
 
    <div style={styles.progressBar}>
      <div style={styles.progressFill} />
      <div style={styles.progressText}>In Entwicklung - 60%</div>
    </div>
    {activeFeature && (
  <div
    style={{
      position: 'fixed',
      inset: 0,
      background: 'linear-gradient(135deg, rgba(0,0,0,0.97), rgba(20,20,20,0.97))',
      backdropFilter: 'blur(30px)',
      zIndex: 1000,
      display: 'flex',
      flexDirection: 'column',
      opacity: showModalAnimation ? 1 : 0,
      transition: 'all 0.6s cubic-bezier(0.16, 1, 0.3, 1)',
    }}
  >
    {/* Fancy Background Effects */}
    <div style={{
      position: 'absolute',
      inset: 0,
      overflow: 'hidden',
      zIndex: 0,
    }}>
      <div style={{
        position: 'absolute',
        top: '10%',
        left: '45%',
        width: '300px',
        height: '300px',
        background: `radial-gradient(circle, ${activeFeature.color}20 0%, transparent 70%)`,
        filter: 'blur(50px)',
        transform: showModalAnimation ? 'scale(1)' : 'scale(0.5)',
        opacity: showModalAnimation ? 1 : 0,
        transition: 'all 1.2s cubic-bezier(0.16, 1, 0.3, 1)',
      }} />
      <div style={{
        position: 'absolute',
        top: '60%',
        left: '25%',
        width: '250px',
        height: '250px',
        background: `radial-gradient(circle, ${activeFeature.color}15 0%, transparent 70%)`,
        filter: 'blur(40px)',
        transform: showModalAnimation ? 'scale(1)' : 'scale(0.5)',
        opacity: showModalAnimation ? 0.8 : 0,
        transition: 'all 1s cubic-bezier(0.16, 1, 0.3, 1) 0.2s',
      }} />
    </div>
 
    {/* Stylish Close Button */}
    <button
      onClick={closeFeature}
      style={{
        position: 'absolute',
        top: '24px',
        right: '24px',
        width: '58px',
        height: '58px',
        background: 'rgba(255,255,255,0.03)',
        border: '1px solid rgba(255,255,255,0.1)',
        borderRadius: '50%',
        color: '#fff',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10,
        transform: showModalAnimation ? 'rotate(0deg) scale(1)' : 'rotate(-180deg) scale(0.8)',
        transition: 'all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1)',
        '&:hover': {
          background: 'rgba(255,255,255,0.1)',
          transform: 'scale(1.1)',
        }
      }}
    >
      <X size={84} />
    </button>
 
    {/* Fancy Video Section */}
    <div style={{
      position: 'relative',
      width: '100%',
      height: '50vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      '@media (min-width: 768px)': {
        height: '70vh',
      }
    }}>
      {/* Stylish Device Frame */}
      <div style={{
        position: 'relative',
        height: '100%',
        width: 'auto',
        aspectRatio: '9/19',
        maxHeight: '85vh',
        padding: '7px',
        background: 'linear-gradient(145deg, rgba(255,255,255,0.1), rgba(255,255,255,0.05))',
        borderRadius: '40px',
        boxShadow: `
          0 20px 40px rgba(0,0,0,0.3),
          0 0 0 1px rgba(255,255,255,0.1),
          inset 0 0 0 1px rgba(255,255,255,0.05)
        `,
        transform: showModalAnimation ? 'translateY(0) scale(1)' : 'translateY(40px) scale(0.95)',
        opacity: showModalAnimation ? 1 : 0,
        transition: 'all 0.8s cubic-bezier(0.34, 1.56, 0.64, 1)',
      }}>
        <div style={{
          width: '100%',
          height: '100%',
          borderRadius: '32px',
          overflow: 'hidden',
          position: 'relative',
          boxShadow: 'inset 0 0 0 1px rgba(255,255,255,0.1)',
        }}>
          {/* Video with Overlay */}
          <div style={{
            position: 'absolute',
            inset: 0,
            background: 'linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, rgba(0,0,0,0.2) 100%)',
            zIndex: 2,
          }} />
          <video
            src={activeFeature.video}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transform: showModalAnimation ? 'scale(1)' : 'scale(1.1)',
              transition: 'transform 1.2s cubic-bezier(0.34, 1.56, 0.64, 1)',
            }}
            autoPlay
            loop
            muted
            playsInline
          />
        </div>
      </div>
    </div>
 
    {/* Content Section */}
    <div style={{
      flex: 1,
      padding: '0 20px 40px',
      maxWidth: '2200px',
      margin: '0 auto',
      width: '100%',
      overflow: 'auto',
      position: 'relative',
      zIndex: 1,
      alignSelf:"center",
      alignItems:"center"
    }}>
      {/* Title Section */}
      <div style={{
        textAlign: 'center',
        marginBottom: '40px',
        transform: showModalAnimation ? 'translateY(0)' : 'translateY(20px)',
        opacity: showModalAnimation ? 1 : 0,
        transition: 'all 0.6s cubic-bezier(0.16, 1, 0.3, 1) 0.3s',
      }}>
        <div style={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: '16px',
          padding: '8px',
          background: 'rgba(255,255,255,0.03)',
          borderRadius: '20px',
          marginBottom: '24px',
        }}>
          <div style={{
            width: '40px',
            height: '40px',
            borderRadius: '12px',
            background: `linear-gradient(135deg, ${activeFeature.color}, ${activeFeature.color}88)`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            {React.cloneElement(activeFeature.icon, { size: 24 })}
          </div>
          <span style={{
            color: activeFeature.color,
            fontSize: '18px',
            fontWeight: '500',
            paddingRight: '12px',
          }}>
            {activeFeature.title}
          </span>
        </div>
       
        <p style={{
          fontSize: '20px',
          lineHeight: 1.6,
          color: 'rgba(255,255,255,0.9)',
          maxWidth: '700px',
          margin: '0 auto',
        }}>
          {activeFeature.description}
        </p>
      </div>
 
      {/* Details Grid */}
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
        gap: '24px',
      }}>
        {activeFeature.details.map((detail, index) => (
          <div key={index} style={{
            background: 'rgba(255,255,255,0.03)',
            borderRadius: '24px',
            padding: '24px',
            border: '1px solid rgba(255,255,255,0.05)',
            transform: showModalAnimation ? 'translateY(0)' : 'translateY(30px)',
            opacity: showModalAnimation ? 1 : 0,
            transition: `all 0.7s cubic-bezier(0.34, 1.56, 0.64, 1) ${0.4 + index * 0.1}s`,
            '&:hover': {
              background: 'rgba(255,255,255,0.05)',
              transform: 'translateY(-2px)',
            }
          }}>
            <p style={{
              fontSize: '16px',
              lineHeight: 1.6,
              color: 'rgba(255,255,255,0.9)',
              display: 'flex',
              gap: '12px',
              alignItems: 'flex-start',
            }}>
              <span style={{
                width: '6px',
                height: '6px',
                borderRadius: '3px',
                background: activeFeature.color,
                marginTop: '10px',
              }} />
              {detail}
            </p>
          </div>
        ))}
      </div>
    </div>
  </div>
)}
    <style jsx global>{`
      @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-30px);
        }
        60% {
          transform: translateY(-15px);
        }
      }
 
      body {
        margin: 0;
        padding: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
        background: #000;
        overflow-x: hidden;
      }
 
      * {
        box-sizing: border-box;
      }
 
      ::-webkit-scrollbar {
        width: 8px;
      }
 
      ::-webkit-scrollbar-track {
        background: #000;
      }
 
      ::-webkit-scrollbar-thumb {
        background: rgba(255,59,48,0.2);
        border-radius: 4px;
      }
    `}</style>
  </div>
);
};
 
export default LandingPage;
 
 