import React, { useState, useEffect, useRef } from 'react';

const EdgeCompatibleVideoPlayer = ({
  src,
  style = {},
  autoPlay = false,
  loop = false,
  muted = true,
  playsInline = true,
  className,
  onLoad
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const videoRef = useRef(null);
  const loadAttempts = useRef(0);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    // Force preload
    video.preload = "auto";

    const handleCanPlayThrough = () => {
      setIsLoaded(true);
      if (onLoad) onLoad();
    };

    const handleError = async (error) => {
      console.error("Video loading error:", error);
      setHasError(true);

      if (loadAttempts.current < 3) {
        loadAttempts.current += 1;

        // Wait a moment before retrying
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Try different video format
        const currentSrc = video.src;
        video.src = '';
        await new Promise(resolve => setTimeout(resolve, 100));
        video.src = currentSrc + '?v=' + loadAttempts.current;

        // Force load
        video.load();
      }
    };

    const handleLoadStart = () => {
      // Force video metadata loading
      if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
        video.requestVideoFrameCallback(() => {
          // Video frame data is available
          setIsLoaded(true);
        });
      }
    };

    // Add event listeners
    video.addEventListener('canplaythrough', handleCanPlayThrough);
    video.addEventListener('error', handleError);
    video.addEventListener('loadstart', handleLoadStart);
    video.addEventListener('loadedmetadata', () => setIsLoaded(true));

    // Force initial load
    video.load();

    return () => {
      video.removeEventListener('canplaythrough', handleCanPlayThrough);
      video.removeEventListener('error', handleError);
      video.removeEventListener('loadstart', handleLoadStart);
      video.removeEventListener('loadedmetadata', () => setIsLoaded(true));
    };
  }, [src, autoPlay]);

  const handleMouseEnter = () => {
    const video = videoRef.current;
    if (video) {
      video.play().catch(error => {
        console.warn("Playback prevented:", error);
      });
      blurOtherVideos();
    }
  };

  const handleMouseLeave = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      video.currentTime = 0; // Reset video to the start
      unblurAllVideos();
    }
  };
  

  const blurOtherVideos = () => {
    const videos = document.querySelectorAll('video');
    videos.forEach(v => {
      if (v !== videoRef.current) {
        v.style.transition = 'filter 2s ease'; // Übergang hinzufügen
        v.style.filter = 'blur(5px)';
      }
    });
  };
  
  const unblurAllVideos = () => {
    const videos = document.querySelectorAll('video');
    videos.forEach(v => {
        v.style.transition = 'filter 1s ease'; // Übergang hinzufügen
      v.style.filter = 'none';
    });
  };
  

  return (
    <div className={className} style={{ position: 'relative', ...style }}>
      {!isLoaded && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(0,0,0,0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white'
        }}>
          Loading...
        </div>
      )}
      <video
        ref={videoRef}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          opacity: isLoaded ? 1 : 0,
          transition: 'opacity 0.3s ease',
          borderRadius:"20px"
        }}
        muted={muted}
        playsInline={playsInline}
        loop={loop}
        autoPlay={autoPlay}
        crossOrigin="anonymous"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <source src={src} type="video/mp4" />
        <source src={src.replace('.mp4', '.webm')} type="video/webm" />
        Your browser does not support the video tag.
      </video>
      {hasError && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
          background: 'rgba(0,0,0,0.7)',
          padding: '10px',
          borderRadius: '5px'
        }}>
          Click to retry loading
        </div>
      )}
    </div>
  );
};

export default EdgeCompatibleVideoPlayer;
